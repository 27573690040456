import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CardHeader, Input, Slider} from "@mui/material";
import {useState} from "react";
import {GridItem, SimpleGrid} from "@chakra-ui/react";
import Grid2 from "@mui/material/Unstable_Grid2";

const factions = [
    {
        "id": 1,
        "name": "Arborec"
    },
    {
        "id": 2,
        "name": "Barony of Letnev"
    },
    {
        "id": 3,
        "name": "Clan of Saar"
    },
    {
        "id": 4,
        "name": "Embers of Muaat"
    },
    {
        "id": 5,
        "name": "Emirates of Hacan"
    },
    {
        "id": 6,
        "name": "Federation of Sol"
    },
    {
        "id": 7,
        "name": "Ghosts of Creuss"
    },
    {
        "id": 8,
        "name": "L1Z1X Mindnet"
    },
    {
        "id": 9,
        "name": "Mentak Coalition"
    },
    {
        "id": 10,
        "name": "Naalu Collective"
    },
    {
        "id": 11,
        "name": "Nekro Virus"
    },
    {
        "id": 12,
        "name": "Sardakk N'orr"
    },
    {
        "id": 13,
        "name": "The Brotherhood of Yin"
    },
    {
        "id": 14,
        "name": "The Embers of Muaat"
    },
    {
        "id": 15,
        "name": "The Empyrean"
    },
    {
        "id": 16,
        "name": "The Mahact Gene-Sorcerers"
    },
    {
        "id": 17,
        "name": "The Naaz-Rokha Alliance"
    },
    {
        "id": 18,
        "name": "The Nomad Alliance"
    },
    {
        "id": 19,
        "name": "The Titans of Ul"
    },
    {
        "id": 20,
        "name": "Universities of Jol-Nar"
    },
    {
        "id": 21,
        "name": "Winnu"
    }
];

const darkTheme = createTheme({
    palette: {
        mode: 'dark',

    },
});

const objectivesExpansion = [{ id: 1 , name: "Expansionist"},{ id: 12, name: "Technocrat"},{ id: 3 , name: "Core-Cracker"}]
const objectivesWarfare = [{ id: 1 , name: "United Front"},{ id: 12, name: "Armada"},{ id: 3 , name: "Secured Assets"}]
const objectivesIndustry = [{ id: 1 , name: "Sourcing Expert"},{ id: 12, name: "Diversified Production"},{ id: 3 , name: "Maximum Efficiency"}]
const objectivesNavigation = [{ id: 1 , name: "Collector"},{ id: 12, name: "Prospector"},{ id: 3 , name: "Mercantilist"}]




function getRandomElements(elements, x) {
    // create a new array to store the randomly chosen elements
    const randomElements = [];

    // create a copy of the elements array
    const elementsCopy = [...elements];



    // loop x times
    for (let i = 0; i < x; i++) {
        // get a random index in the range 0 to the length of the elements array copy
        const randomIndex = Math.floor(Math.random() * elementsCopy.length);

        // add the element at the random index to the array of random elements
        randomElements.push(elementsCopy[randomIndex]);

        // remove the element from the elements array copy so that it cannot be chosen again
        elementsCopy.splice(randomIndex, 1);
    }

    // return the array of random elements
    return randomElements;
}


export default function Main() {

    const [hitRandomize, setHitRandomize] = useState(false);
    const [randomFactions, setRandomFactions] = useState([[]]);
    const [randomNavigationMat, setRandomNavigationMat] = useState([]);
    const [randomObjectives, setRandomObjectives] = useState([]);
    const [playerNames, setPlayerNames] = useState([{id: '1', name: 'Player 1'}, {id: '2', name: 'Player 2'}]);


    const randomize = () => {

        // Generates an Array of playerNames.length Arrays containing 3 random factions without duplicates.
        // Also every faction is only used once in the whole array
        let ranFac = [];
        for(let i = 0; i < playerNames.length; i++) {
            let temp = [];
            while(temp.length < 3){
                let r = Math.floor(Math.random() * 21) + 1;
                if(temp.indexOf(r) === -1 && ranFac.every((arr) => arr.indexOf(r) === -1)) temp.push(r);
            }
            ranFac.push(temp);
        }


        // Generates Array of four Arrays including random numbers from 1 to 8 sized by playerNames.length. Each Individual array does not contain duplicates
        // This is used for the random player mats
        let ranMat = [];
        for(let i = 0; i < 4; i++) {
            let temp = [];
            while(temp.length < playerNames.length){
                let r = Math.floor(Math.random() * 8) + 1;
                if(temp.indexOf(r) === -1) temp.push(r);
            }
            ranMat.push(temp);
        }

        setRandomNavigationMat(ranMat);
        setRandomFactions(ranFac);
        console.log(ranFac);
        setRandomObjectives([getRandomElements(objectivesExpansion, 1), getRandomElements(objectivesNavigation, 1), getRandomElements(objectivesIndustry, 1), getRandomElements(objectivesWarfare, 1)]);
        setHitRandomize(true);
    }

    const handlePlayers = (value) => {
        console.log(value);
        setHitRandomize(false);
        let playerNames = [];
        for (let i = 0; i < value; i++) {
            playerNames.push({id: i + 1+'', name: 'Player ' + (i + 1)})
        }
        setPlayerNames(playerNames);
    }

    const handlePlayerNames = (value, index) => {
        console.log(value);
        const updatedPlayerNames = [...playerNames];
        updatedPlayerNames[index].name = value;
        setPlayerNames(updatedPlayerNames);
    }


    return (<ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <AppBar position="relative">
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                    Twilight Inscription Setup Tool v0.2
                </Typography>
            </Toolbar>
        </AppBar>
        <main>
            <Box sx={{bgcolor: 'background.paper', pt: 8, pb: 6}}>
                <Container maxWidth="sm">
                    <Typography
                        component="h2"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Select number of players and click setup
                    </Typography>

                    <Slider
                        aria-label="Always visible"
                        defaultValue={2}
                        min={2}
                        max={7}
                        step={1}
                        marks={true}
                        onChange={(_, value) => handlePlayers(value)}
                        valueLabelDisplay="on"
                    />


                    {playerNames.length >= 2 && <Input fullWidth id={playerNames[0].id} placeholder={'Player 1'}
                                                       onChange={(e) => handlePlayerNames(e.target.value, 0)}></Input>}
                    {playerNames.length  >= 2 && <Input fullWidth id={playerNames[1].id} placeholder={'Player 2'}
                                                        onChange={(e) => handlePlayerNames(e.target.value, 1)}></Input>}
                    {playerNames.length  >= 3 && <Input fullWidth id={playerNames[2].id} placeholder={'Player 3'}
                                                        onChange={(e) => handlePlayerNames(e.target.value, 2)}></Input>}
                    {playerNames.length  >= 4 && <Input fullWidth id={playerNames[3].id} placeholder={'Player 4'}
                                                        onChange={(e) => handlePlayerNames(e.target.value, 3)}></Input>}
                    {playerNames.length  >= 5 && <Input fullWidth id={playerNames[4].id} placeholder={'Player 5'}
                                                        onChange={(e) => handlePlayerNames(e.target.value, 4)}></Input>}
                    {playerNames.length  >= 6 && <Input fullWidth id={playerNames[5].id} placeholder={'Player 6'}
                                                        onChange={(e) => handlePlayerNames(e.target.value, 5)}></Input>}
                    {playerNames.length  >= 7 && <Input fullWidth id={playerNames[6].id} placeholder={'Player 7'}
                                                        onChange={(e) => handlePlayerNames(e.target.value, 6)}></Input>}
                    {playerNames.length  >= 8 && <Input fullWidth id={playerNames[7].id} placeholder={'Player 8'}
                                                        onChange={(e) => handlePlayerNames(e.target.value, 6)}></Input>}


                    <Stack
                        sx={{pt: 4}}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Button variant="contained" onClick={randomize}>Randomize</Button>

                    </Stack>
                </Container>
            </Box>
            { hitRandomize &&
                <Container maxWidth="md">
                    <Grid container>


                        <Grid item xs={12} md={12} sx={{ ml:1 }}>
                            <Card sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                <CardHeader title="Objectives" titleTypographyProps={{align: 'center'}}>
                                </CardHeader>
                            </Card>
                        </Grid>



                        <Grid container sx={{ ml: 0 , mr: 0 , mt: 1}} spacing={1}>
                            <Grid item xs={6} md={3}>
                                    <Card>
                                        <CardContent>
                                            <Typography align={'center'}>
                                                <b>Expansion</b>
                                            </Typography>
                                            <Typography align={'center'}>
                                                {randomObjectives[0][0].name}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                            </Grid>
                            <Grid item xs={6} md={3}>

                                    <Card>
                                        <CardContent>
                                            <Typography align={'center'}>
                                                <b>Warfare</b>
                                            </Typography>
                                            <Typography align={'center'}>
                                                {randomObjectives[1][0].name}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                            </Grid>
                            <Grid item xs={6} md={3}>

                                    <Card>
                                        <CardContent>
                                            <Typography align={'center'}>
                                                <b>Industry</b>
                                            </Typography>
                                            <Typography align={'center'}>
                                                {randomObjectives[2][0].name}
                                            </Typography>
                                        </CardContent>
                                    </Card>

                            </Grid>
                            <Grid item xs={6} md={3}>
                                    <Card>
                                        <CardContent>
                                            <Typography align={'center'}>
                                                <b>Navigation</b>
                                            </Typography>
                                            <Typography align={'center'}>
                                                {randomObjectives[3][0].name}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                            </Grid>
                        </Grid>


                        <Grid container sx={{ ml: 0 , mr: 0 , mt: 1}} spacing={1}>
                            {playerNames.map((name, id) => (<Grid item key={id} xs={12} sm={6} md={3}>
                                <Card
                                    sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
                                >
                                    <CardHeader title={playerNames[id].name} titleTypographyProps={{align: 'center'}}>

                                    </CardHeader>

                                    <CardContent sx={{flexGrow: 1}}>
                                        <Typography align={'center'}>
                                            <b>Factions</b>
                                        </Typography>
                                        <Typography align={'center'}>
                                            {factions[randomFactions[id][0]-1].name}
                                        </Typography>
                                        <Typography align={'center'}>
                                            {factions[randomFactions[id][1]-1].name}
                                        </Typography>
                                        <Typography align={'center'}>
                                            {factions[randomFactions[id][2]-1].name}
                                        </Typography>
                                        <Typography align={'center'} sx={{ mt: 5 }}>
                                            <b>Mat's</b>
                                        </Typography>
                                        <Typography align={'center'} >
                                            Navigation: {randomNavigationMat[0][id]}
                                        </Typography>
                                        <Typography align={'center'}>
                                            Expansion: {randomNavigationMat[1][id]}
                                        </Typography>
                                        <Typography align={'center'}>
                                            Warfare: {randomNavigationMat[2][id]}
                                        </Typography>
                                        <Typography align={'center'}>
                                            Industry: {randomNavigationMat[3][id]}
                                        </Typography>
                                    </CardContent>

                                </Card>
                            </Grid>))}

                        </Grid>


                    </Grid>
                </Container>
            }
        </main>
    </ThemeProvider>);
}